<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-28 19:48:44
 * @LastEditTime: 2021-09-29 15:56:32
 * @Description:月度合同执行情况表
 * @Param:
 * @FilePath: \JT_Web_test\src\views\statisticalAnalysis\statisticalReport\monthlyContractExecution.vue
 -->

<template>
    <div class="monthlyContractExecution" v-loading="loading">
        <div class="monthlyContractExecution_content reportForm_style">
            <div class="reportForm_head">
                <div class="queryCriteria">
                    <div>
                        <el-date-picker
                            v-model="date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            @change="initPage"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="monthlyContractExecution_table table-bg">
                <el-table
                    :data="tableData"
                    height="100%"
                    border
                    :span-method="objectSpanMethod"
                >
                    <el-table-column
                        prop="area_name"
                        label="片区"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="tenant_name"
                        label="公司"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="contract_number"
                        label="合同编号"
                        width="240"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="client_name"
                        label="客户"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="engineering_name"
                        label="工程项目"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="projected_capacity"
                        label="预计方量（方）"
                        width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="salesman_name"
                        label="业务责任人"
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="月度销售"
                    >
                        <el-table-column
                            prop="deliver_volume"
                            label="数量(方)"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="money_receivable"
                            label="应付款金额(元)"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="money_actual"
                            label="实付款金额(元)"
                            width="120"
                        >
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="累计销售"
                    >
                        <el-table-column
                            prop="money_total_receivable"
                            label="累计应收"
                            width="100"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="money_total_actual"
                            label="累计实收"
                            width="100"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="money_total_remaining"
                            label="应收账款余额"
                            width="120"
                        >
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/utils/util';

export default {
    name: 'monthly-contract-execution',
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            date: '',
            tableData: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.date = utils.currentTimeFormat('yyyy-MM-dd');
        this.initPage();
    },
    mounted() {},
    destroyed() {},
    methods: {
        async initPage() {
            this.loading = true;
            const row = await this.getTableData();
            this.tableData = this.dataProcessing(row);
            this.loading = false;
        },
        flitterData(arr) {
            const spanOneArr = [];
            let concatOne = 0;
            arr.forEach((item, index) => {
                if (index === 0) {
                    spanOneArr.push(1);
                } else if (item.area_name === arr[index - 1].area_name) {
                    spanOneArr[concatOne] += 1;
                    spanOneArr.push(0);
                } else {
                    spanOneArr.push(1);
                    concatOne = index;
                }
            });
            return {
                one: spanOneArr,
            };
        },
        // eslint-disable-next-line consistent-return
        objectSpanMethod({ rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.flitterData(this.tableData).one[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col,
                };
            }
        },
        dataProcessing(data) {
            const returnData = [];
            const result = data.reduce((a, b) => {
                if (a[b.area_id]) {
                    a[b.area_id].push(b);
                } else {
                    a[b.area_id] = [b];
                }
                return a;
            }, {});
            for (const k in result) {
                returnData.push(...result[k]);
            }
            return returnData;
        },
        getTableData() {
            const Url = `/interfaceApi/datacenter/thematic/sales/getmonth_contract_implement/total?date=${this.date}`;
            return this.$axios.$get(Url, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
@import './CSS/index.stylus';
.monthlyContractExecution
    height 100%
    padding 0.2rem
    .monthlyContractExecution_content
        height 100%
        background #fff
        padding 0.14rem
        .monthlyContractExecution_table
            height calc(100% - 0.5rem)
        >>>.el-table
            th
                border-bottom 1px solid rgba(215,215,215,0.7)
            thead
                tr
                    &:nth-of-type(1)
                        th
                            background #EDF0F5
                    &:nth-of-type(2)
                        th
                            background #F6F8FC
</style>